jQuery(document).ready(function ($) {
  // Tab and Card function
  function setTabAndCard(e) {
    let activeIndex;

    activeIndex = e.data("card");

    //removing active classes
    $(".def6-block.card-navigation .card-tab li a").removeClass("active");
    $(".def6-block.card-navigation .card-content li a").removeClass("active");

    //adding active classes
    e.addClass("active");
    $(
      `.def6-block.card-navigation .card-content li a[data-card="${activeIndex}"]`
    ).addClass("active");
  }

  // Indicator bar function
  function setIndicator(e) {
    switch (e.data("card")) {
      case 0:
        $(".def6-block.card-navigation .indicators-wrap .indicator").addClass(
          "index-0"
        );
        $(
          ".def6-block.card-navigation .indicators-wrap .indicator"
        ).removeClass("index-1");
        $(
          ".def6-block.card-navigation .indicators-wrap .indicator"
        ).removeClass("index-2");
        return;
      case 1:
        $(
          ".def6-block.card-navigation .indicators-wrap .indicator"
        ).removeClass("index-0");
        $(".def6-block.card-navigation .indicators-wrap .indicator").addClass(
          "index-1"
        );
        $(
          ".def6-block.card-navigation .indicators-wrap .indicator"
        ).removeClass("index-2");

        return;
      case 2:
        $(
          ".def6-block.card-navigation .indicators-wrap .indicator"
        ).removeClass("index-0");
        $(
          ".def6-block.card-navigation .indicators-wrap .indicator"
        ).removeClass("index-1");
        $(".def6-block.card-navigation .indicators-wrap .indicator").addClass(
          "index-2"
        );
        return;
    }
  }

  // Click event to switch tabs and corresponding tab content
  $(".def6-block.card-navigation .card-tab li a").click(function (e) {
    e.preventDefault();
    if (!$(this).hasClass("active")) {
      setTabAndCard($(this));
    }

    setIndicator($(this));
  });

  $(".def6-block.card-navigation .card-tab li a").keypress(function (event) {
    event.preventDefault();
    if (event.which == 13) {
      event.preventDefault();

      setTabAndCard($(this));

      setIndicator($(this));
    }
  });

  // Previous navigation button
  // $(".def6-block.card-navigation .card-prev").click(function () {
  //   let activeCard = $(".def6-block.card-navigation .card-element.active");
  //   let activeTab = $(".def6-block.card-navigation .card-tab li.active");
  //   let indicatorIndex = $(".def6-block.card-navigation .indicators-wrap .indicator").attr('class');
  //   indicatorIndex = indicatorIndex.substr(indicatorIndex.length - 1);
    
  //   if (activeCard.data("card") < 1) {
  //     // Do nothing
  //   } else {
  //       $(".def6-block.card-navigation .card-tab li").removeClass("active");
  //       $(".def6-block.card-navigation .card-content li").removeClass("active");
  //       activeCard.prev().addClass("active");
  //       activeTab.prev().addClass("active");
  //       $(".indicators-wrap .indicator").removeClass(`index-${indicatorIndex}`);
  //       $(".indicators-wrap .indicator").addClass(`index-${indicatorIndex-1}`);
  //     }
    
  // })

  // Next navigation button
  // $(".def6-block.card-navigation .card-next").click(function () {
  //   let activeCard = $(".def6-block.card-navigation .card-element.active");
  //   let activeTab = $(".def6-block.card-navigation .card-tab li.active");
  //   let indicatorIndex = $(".def6-block.card-navigation .indicators-wrap .indicator").attr('class');
  //   indicatorIndex = indicatorIndex.substr(indicatorIndex.length - 1);
    
  //   if (activeCard.data("card") > 1) {
  //     // Do nothing
  //   } else {
  //       $(".def6-block.card-navigation .card-tab li").removeClass("active");
  //       $(".def6-block.card-navigation .card-content li").removeClass("active");
  //       activeCard.next().addClass("active");
  //       activeTab.next().addClass("active");
  //       $(".indicators-wrap .indicator").removeClass(`index-${indicatorIndex}`);
  //       $(".indicators-wrap .indicator").addClass(`index-${Number(indicatorIndex)+1}`);
  //     }
    
  // })


  $(function(){
    var index = 0;
    var $tabs = $('a.card-tab--tab');
  
    $tabs.bind(
    {
      // on keydown,
      // determine which tab to select
      keydown: function(ev){
        var LEFT_ARROW = 37;
        var UP_ARROW = 38;
        var RIGHT_ARROW = 39;
        var DOWN_ARROW = 40;
  
        var k = ev.which || ev.keyCode;
  
        // if the key pressed was an arrow key
        if (k >= LEFT_ARROW && k <= DOWN_ARROW){
          // move left one tab for left and up arrows
          if (k == LEFT_ARROW || k == UP_ARROW){
            if (index > 0) {
              index--;
            }
            // unless you are on the first tab,
            // in which case select the last tab.
            else {
              index = $tabs.length - 1;
            }
          }
  
          // move right one tab for right and down arrows
          else if (k == RIGHT_ARROW || k == DOWN_ARROW){
            if (index < ($tabs.length - 1)){
              index++;
            }
            // unless you're at the last tab,
            // in which case select the first one
            else {
              index = 0;
            }
          }
  
          // trigger a click event on the tab to move to
          $($tabs.get(index)).click();
          ev.preventDefault();
        }
      },
  
      // just make the clicked tab the selected one
      click: function(ev){
        index = $.inArray(this, $tabs.get());
        setFocus();
        ev.preventDefault();
      }
    });
  
    var setFocus = function(){
      // undo tab control selected state,
      // and make them not selectable with the tab key
      // (all tabs)
      $tabs.attr(
      {
        tabindex: '-1',
        'aria-selected': 'false'
      }).removeClass('active');
  
      // hide all tab panels.
      $('.card-element').removeClass('active');
  
      // make the selected tab the selected one, shift focus to it
      $($tabs.get(index)).attr(
      {
        tabindex: '0',
        'aria-selected': 'true'
      }).addClass('active').focus();
  
      // handle parent <li> current class (for coloring the tabs)
      $($tabs.get(index)).parent().siblings().removeClass('active');
      $($tabs.get(index)).parent().addClass('active');
  
      // add a current class also to the tab panel
      // controlled by the clicked tab
      $($($tabs.get(index)).attr('href')).addClass('active');
    };
  });


});


